import { axiosInstance } from '../Axios';
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { setModal, storeData } from './UiReducer';

const HomeReducer = createSlice({
	name: 'home',
	initialState: {
		success: false,
		error: null,
		loading: false,
		categ: null,
		quest: sessionStorage.getItem('quest')
			? JSON.parse(sessionStorage.getItem('quest'))
			: null,
		option: null,
		status: null,
		response: null,
		userR: null,
		userLogin: sessionStorage.getItem('userLogin')
			? JSON.parse(sessionStorage.getItem('userLogin'))
			: null,
		otpverify: null,
		statusUser: null,
		updateUser: null,
		updateContent: null,
		gender: null,
		activity: null,
		meal: null,
		diary: null,
		mealplan: null,
		recipeplan: null,
		aiimage: null,
		desc: null,
		airecipe: null,
		mail: null,
		bulkImage: null,
		loading2: false,
		des: null,
		loading3: false,
		recipetitle: '',
		profile: null,
		event: null,
	},
	reducers: {
		categRequest(state, action) {
			state.loading = true;
		},
		categSuccess(state, action) {
			state.loading = false;
			state.categ = action.payload;
			state.error = null;
		},
		categFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		questRequest(state, action) {
			state.loading = true;
		},
		questSuccess(state, action) {
			state.loading = false;
			state.quest = action.payload;
			storeData('quest', action.payload);
			state.error = null;
		},
		questFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		optionRequest(state, action) {
			state.loading = true;
		},
		optionSuccess(state, action) {
			state.loading = false;
			state.option = action.payload;
			state.error = null;
		},
		optionFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		statusRequest(state, action) {
			state.loading = true;
		},
		statusSuccess(state, action) {
			state.loading = false;
			state.status = action.payload;
			state.error = null;
		},
		statusFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		resRequest(state, action) {
			state.loading2 = true;
		},
		resSuccess(state, action) {
			state.loading2 = false;
			state.response = action.payload;
			state.error = null;
		},
		resFail(state, action) {
			state.loading2 = false;
			state.error = action.payload;
		},
		userRequest(state, action) {
			state.loading = true;
		},
		userSuccess(state, action) {
			state.loading = false;
			// state.userLogin = action.payload;
			// storeData('userLogin', action.payload);
			state.error = null;
		},
		userFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		userLoginRequest(state, action) {
			state.loading = true;
		},
		userLoginSuccess(state, action) {
			state.loading = false;
			state.userLogin = action.payload;
			storeData('userLogin', action.payload);
			state.error = null;
		},
		userLoginFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		otpverifyRequest(state, action) {
			state.loading = true;
		},
		otpverifySuccess(state, action) {
			state.loading = false;
			state.otpverify = action.payload;
			// state.userLogin = action.payload;
			// storeData('userLogin', action.payload);
			state.error = null;
		},
		otpverifyFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		statusUserRequest(state, action) {
			state.loading = true;
		},
		statusUserSuccess(state, action) {
			state.loading = false;
			state.statusUser = action.payload;
			state.error = null;
		},
		statusUserFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		updateUserRequest(state, action) {
			state.loading = true;
		},
		updateUserSuccess(state, action) {
			state.loading = false;
			state.updateUser = action.payload;
			state.userLogin = action.payload;
			storeData('userLogin', action.payload);
			state.error = null;
		},
		updateUserFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		updateContentRequest(state, action) {
			state.loading3 = true;
		},
		updateContentSuccess(state, action) {
			state.loading3 = false;
			state.updateContent = action.payload;
			state.error = null;
		},
		updateContentFail(state, action) {
			state.loading3 = false;
			state.error = action.payload;
		},
		genderRequest(state, action) {
			state.loading = true;
		},
		genderSuccess(state, action) {
			state.loading = false;
			state.gender = action.payload;
			state.userLogin = action.payload;
			storeData('userLogin', action.payload);
			state.error = null;
		},
		genderFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		activitylevelRequest(state, action) {
			state.loading = true;
		},
		activitylevelSuccess(state, action) {
			state.loading = false;
			state.activity = action.payload;
			state.userLogin = action.payload;
			storeData('userLogin', action.payload);
			state.error = null;
		},
		activitylevelFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		mealspreferdayRequest(state, action) {
			state.loading = true;
		},
		mealspreferdaySuccess(state, action) {
			state.loading = false;
			state.meal = action.payload;
			state.userLogin = action.payload;
			storeData('userLogin', action.payload);
			state.error = null;
		},
		mealspreferdayFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		dietarypreferencesRequest(state, action) {
			state.loading = true;
		},
		dietarypreferencesSuccess(state, action) {
			state.loading = false;
			state.diary = action.payload;
			state.userLogin = action.payload;
			storeData('userLogin', action.payload);
			state.error = null;
		},
		dietarypreferencesFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		mealplanRequest(state, action) {
			state.loading = true;
		},
		mealplanSuccess(state, action) {
			state.loading = false;
			state.mealplan = action.payload;
			state.error = null;
		},
		mealplanFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		recipeplanRequest(state, action) {
			state.loading = true;
		},
		recipeplanSuccess(state, action) {
			state.loading = false;
			state.recipeplan = action.payload;
			state.error = null;
		},
		recipeplanFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		imageGenerateRequest(state, action) {
			state.loading = true;
		},
		imageGenerateSuccess(state, action) {
			state.loading = false;
			state.aiimage = action.payload;
			state.error = null;
		},
		imageGenerateFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		recipeGenerateRequest(state, action) {
			state.loading = true;
		},
		recipeGenerateSuccess(state, action) {
			state.loading = false;
			state.airecipe = action.payload;
			state.error = null;
		},
		recipeGenerateFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		descGenerateRequest(state, action) {
			state.loading = true;
		},
		descGenerateSuccess(state, action) {
			state.loading = false;
			state.desc = action.payload;
			state.error = null;
		},
		descGenerateFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		mailRequest(state, action) {
			state.loading = true;
		},
		mailSuccess(state, action) {
			state.loading = false;
			state.mail = action.payload;
			state.error = null;
		},
		mailFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		bulkImageRequest(state, action) {
			state.loading = true;
		},
		bulkImageSuccess(state, action) {
			state.loading = false;
			state.bulkImage = action.payload;
			state.error = null;
		},
		bulkImageFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		desGenerateRequest(state, action) {
			state.loading = true;
		},
		desGenerateSuccess(state, action) {
			state.loading = false;
			state.des = action.payload;
			state.error = null;
		},
		desGenerateFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		recipetitleGenerateRequest(state, action) {
			state.loading = true;
		},
		recipetitleGenerateSuccess(state, action) {
			state.loading = false;
			state.recipetitle = action.payload;
			state.error = null;
		},
		recipetitleGenerateFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		profileRequest(state, action) {
			state.loading = true;
		},
		profileSuccess(state, action) {
			state.loading = false;
			state.userLogin = action.payload;
			storeData('userLogin', action.payload);
			state.error = null;
		},
		profileFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		eventRequest(state, action) {
			state.loading = true;
		},
		eventSuccess(state, action) {
			state.loading = false;
			state.event = action.payload;
			storeData('event', action.payload);
			state.error = null;
		},
		eventFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
	},
});

const { actions } = HomeReducer;
const config = {
	headers: {
		Accept: 'application/json',
	},
};
export const {
	categRequest,
	categSuccess,
	categFail,
	questRequest,
	questSuccess,
	questFail,
	optionRequest,
	optionSuccess,
	optionFail,
	statusRequest,
	statusSuccess,
	statusFail,
	resRequest,
	resSuccess,
	resFail,
	userRequest,
	userSuccess,
	userFail,
	userLoginRequest,
	userLoginSuccess,
	userLoginFail,
	otpverifyRequest,
	otpverifySuccess,
	otpverifyFail,
	statusUserRequest,
	statusUserSuccess,
	statusUserFail,
	updateUserRequest,
	updateUserSuccess,
	updateUserFail,
	updateContentRequest,
	updateContentSuccess,
	updateContentFail,
	genderRequest,
	genderSuccess,
	genderFail,
	activitylevelRequest,
	activitylevelSuccess,
	activitylevelFail,
	mealspreferdayRequest,
	mealspreferdaySuccess,
	mealspreferdayFail,
	dietarypreferencesRequest,
	dietarypreferencesSuccess,
	dietarypreferencesFail,
	mealplanRequest,
	mealplanSuccess,
	mealplanFail,
	recipeplanRequest,
	recipeplanSuccess,
	recipeplanFail,
	imageGenerateRequest,
	imageGenerateSuccess,
	imageGenerateFail,
	recipeGenerateRequest,
	recipeGenerateSuccess,
	recipeGenerateFail,
	descGenerateRequest,
	descGenerateSuccess,
	descGenerateFail,
	mailRequest,
	mailSuccess,
	mailFail,
	bulkImageRequest,
	bulkImageSuccess,
	bulkImageFail,
	desGenerateRequest,
	desGenerateSuccess,
	desGenerateFail,
	recipetitleGenerateRequest,
	recipetitleGenerateSuccess,
	recipetitleGenerateFail,
	profileRequest,
	profileSuccess,
	profileFail,
	eventRequest,
	eventSuccess,
	eventFail,
} = actions;

export const categoryDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(categRequest());
		const { data } = await axiosInstance.get('/category', config);
		console.log('data', data);
		dispatch(categSuccess(data));
	} catch (error) {
		dispatch(
			categFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const QuestionDispatch = (id) => async (dispatch) => {
	try {
		dispatch(questRequest());
		const { data } = await axiosInstance.get(`/question/${id}`, config);
		console.log('data', data);
		dispatch(questSuccess(data));
	} catch (error) {
		dispatch(
			questFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const OptionDispatch = (id) => async (dispatch) => {
	try {
		dispatch(optionRequest());
		const { data } = await axiosInstance.get(`/option/${id}`, config);
		console.log('data', data);
		dispatch(optionSuccess(data));
		if (data?.question?.length > 0) {
			let val = data?.question?.filter((person) => person.status === 'active');
			if (val?.length > 0) {
				dispatch(OptionStatusDispatch(val[0].id, 'inactive'));
			}
		}
	} catch (error) {
		dispatch(
			optionFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const OptionStatusDispatch =
	(id, value, qid, len) => async (dispatch) => {
		try {
			let form = {
				id: id,
				status: value,
			};
			dispatch(statusRequest());

			const { data } = await axiosInstance.post('/optionStatus', form, config);
			console.log('data', data);
			dispatch(statusSuccess(data));
			if (data.status === 'success') {
				if (len) {
					dispatch(OptionDispatch(qid));
				}
			}
		} catch (error) {
			dispatch(
				statusFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const ResponseGenerateDispatch =
	(bodyData, navigate, dat) => async (dispatch) => {
		try {
			dispatch(resRequest());
			const { data } = await axiosInstance.post(
				'/responsegenerate',
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(resSuccess(data));
			if (data) {
				let form = {
					email: bodyData.email,
					content: bodyData.content,
				};
				dispatch(updateContentDispatch(form));
				// alert('Congratulations you AI Meal Plan has been send your email. ');
				navigate('/resp', { state: { data: data, body: bodyData } });
			}
		} catch (error) {
			dispatch(
				resFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const userRegisterDispatch =
	(bodyData, navigate) => async (dispatch) => {
		try {
			dispatch(userRequest());

			const { data } = await axiosInstance.post(
				'/user/register',
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(userSuccess(data));
			if (data.status === 'success') {
				let form = {
					email: bodyData?.email,
					event_name: 'OTP SEND',
				};
				dispatch(eventDispatch(form));
				alert(data?.message);
				// alert('Registration Successfully');
				navigate('/');
			} else {
				alert(data?.message);
			}
		} catch (error) {
			alert(error.response.data.message);
			dispatch(
				userFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const userLoginDispatch = (bodyData, navigate) => async (dispatch) => {
	try {
		dispatch(userLoginRequest());

		const { data } = await axiosInstance.post('/user/login', bodyData, config);
		console.log('data', data);
		dispatch(userLoginSuccess(data));
		if (data.status === 'success') {
			navigate('/dashboard');
			alert('Login Successfully');
			dispatch(mealplanDispatch(data?.result?.id));
			dispatch(recipeplanDispatch(data?.result?.id));
		} else {
			alert(data?.message);
		}
	} catch (error) {
		alert(error.response.data.message);
		console.log('error', error);
		dispatch(
			userLoginFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const otpVerifyDispatch = (bodyData, navigate) => async (dispatch) => {
	try {
		dispatch(otpverifyRequest());

		const { data } = await axiosInstance.post(
			'/user/VerifyOtp',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(otpverifySuccess(data));
		if (data.status === 'success') {
			let form = {
				email: data?.result?.email,
				event_name: 'OTP FILLED',
			};
			dispatch(eventDispatch(form));
			dispatch(updateStatusDispatch(form, navigate));
		} else {
			alert('Token might be Expired');
			navigate('/');
		}
	} catch (error) {
		alert('Token might be Expired');
		navigate('/');
		dispatch(
			otpverifyFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const updateStatusDispatch =
	(bodyData, navigate) => async (dispatch) => {
		try {
			let form = {
				email: bodyData.email,
				status: 'active',
			};
			dispatch(statusUserRequest());

			const { data } = await axiosInstance.post(
				'/user/updateStatus',
				form,
				config
			);
			console.log('data', data);
			dispatch(statusUserSuccess(data));
			if (data.status === 'success') {
				navigate('/getstarted', { state: bodyData });
				// alert('Registration Successfully');
				// navigate('/otp');
			} else {
				alert('Failed');
			}
		} catch (error) {
			alert('Failed');
			dispatch(
				statusUserFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const updateUserDispatch = (bodyData, navigate) => async (dispatch) => {
	try {
		dispatch(updateUserRequest());

		const { data } = await axiosInstance.post(
			'/user/updateUser',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(updateUserSuccess(data));
		if (data.status === 'success') {
			navigate('/generalQuestion');
			// dispatch(setModal(true));
			dispatch(mealplanDispatch(data?.result?.id));
			dispatch(recipeplanDispatch(data?.result?.id));
		} else {
			alert('Failed');
		}
	} catch (error) {
		alert('Failed');
		dispatch(
			updateUserFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const updateContentDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(updateContentRequest());

		const { data } = await axiosInstance.post(
			'/user/updatecontent',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(updateContentSuccess(data));
	} catch (error) {
		alert('Failed');
		dispatch(
			updateContentFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const updateGenderDispatch =
	(bodyData, setStep, step) => async (dispatch) => {
		try {
			dispatch(genderRequest());

			const { data } = await axiosInstance.post(
				'/user/updateGender',
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(genderSuccess(data));
			if (data.status === 'success') {
				setStep(step + 1);
			}
		} catch (error) {
			alert('Failed');
			dispatch(
				genderFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const updateactivitylevelDispatch =
	(bodyData, setStep, step) => async (dispatch) => {
		try {
			dispatch(activitylevelRequest());

			const { data } = await axiosInstance.post(
				'/user/updateactivitylevel',
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(activitylevelSuccess(data));
			if (data.status === 'success') {
				setStep(step + 1);
			}
		} catch (error) {
			alert('Failed');
			dispatch(
				activitylevelFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const updatedietarypreferencesDispatch =
	(bodyData, setStep, step) => async (dispatch) => {
		try {
			dispatch(dietarypreferencesRequest());

			const { data } = await axiosInstance.post(
				'/user/updatedietarypreferences',
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(dietarypreferencesSuccess(data));
			if (data.status === 'success') {
				setStep(step + 1);
			}
		} catch (error) {
			alert('Failed');
			dispatch(
				dietarypreferencesFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const updatemealspreferdayDispatch =
	(bodyData, navigate) => async (dispatch) => {
		try {
			dispatch(mealspreferdayRequest());

			const { data } = await axiosInstance.post(
				'/user/updatemealspreferday',
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(mealspreferdaySuccess(data));
			if (data.status === 'success') {
				// setStep(step + 1);
				// dispatch(setModal(false));
				navigate('/category');
			}
		} catch (error) {
			alert('Failed');
			dispatch(
				mealspreferdayFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const mealplanDispatch = (id) => async (dispatch) => {
	try {
		dispatch(mealplanRequest());
		const { data } = await axiosInstance.get(`/user/mealplan/${id}`, config);
		console.log('data', data);
		dispatch(mealplanSuccess(data));
	} catch (error) {
		dispatch(
			mealplanFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const recipeplanDispatch = (id) => async (dispatch) => {
	try {
		dispatch(recipeplanRequest());
		const { data } = await axiosInstance.get(`/user/recipeplan/${id}`, config);
		console.log('data', data);
		dispatch(recipeplanSuccess(data));
	} catch (error) {
		dispatch(
			recipeplanFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const imageGenerateDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(imageGenerateRequest());

		const { data } = await axiosInstance.post(
			'/imagegenerate',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(imageGenerateSuccess(data));
		if (data.status === 'success') {
		}
	} catch (error) {
		alert('Failed');
		dispatch(
			imageGenerateFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const recipeGenerateDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(recipeGenerateRequest());

		const { data } = await axiosInstance.post(
			'/recipegenerate',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(recipeGenerateSuccess(data));
		if (data.status === 'success') {
		}
	} catch (error) {
		alert('Failed');
		dispatch(
			recipeGenerateFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const descGenerateDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(descGenerateRequest());

		const { data } = await axiosInstance.post(
			'/recipegenerate',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(descGenerateSuccess(data));
		if (data.status === 'success') {
		}
	} catch (error) {
		alert('Failed');
		dispatch(
			descGenerateFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const mailDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(mailRequest());

		const { data } = await axiosInstance.post('/mail', bodyData, config);
		console.log('data', data);
		dispatch(mailSuccess(data));
	} catch (error) {
		// alert('Failed');
		dispatch(
			mailFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const bulkImageDispatch =
	(bodyData, mealData, recipeLinks, body) => async (dispatch) => {
		try {
			dispatch(bulkImageRequest());

			const { data } = await axiosInstance.post(
				'/bulkimagegenerate',
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(bulkImageSuccess(data));
			if (data.status === 'success') {
				let form = {
					email: body?.email,
					CampaignID: 'dc5427e8-7647-593b-a384-a1d37ffa3ad3',
					cat: body?.cat,
					data: {
						name: body?.name,
					},
					blocks: [
						{
							blockId: 'blockz1vv457',
							data: [
								{
									image: data?.image1,
									title: 'Breakfast',
									mealname: mealData[0]?.meal,
									calories: mealData[0]?.nutrition?.Calories,
									protien: mealData[0]?.nutrition?.Protein,
									carbs: mealData[0]?.nutrition?.Carbs,
									fat: mealData[0]?.nutrition?.Fat,
									link: recipeLinks[0]?.link,
								},
								{
									image: data?.image2,
									title: 'Snack',
									mealname: mealData[1]?.meal,
									calories: mealData[1]?.nutrition?.Calories,
									protien: mealData[1]?.nutrition?.Protein,
									carbs: mealData[1]?.nutrition?.Carbs,
									fat: mealData[1]?.nutrition?.Fat,
									link: recipeLinks[1]?.link,
								},
								{
									image: data?.image3,
									title: 'Lunch',
									mealname: mealData[2]?.meal,
									calories: mealData[2]?.nutrition?.Calories,
									protien: mealData[2]?.nutrition?.Protein,
									carbs: mealData[2]?.nutrition?.Carbs,
									fat: mealData[2]?.nutrition?.Fat,
									link: recipeLinks[2]?.link,
								},
								{
									image: data?.image4,
									title: 'Snack',
									mealname: mealData[3]?.meal,
									calories: mealData[3]?.nutrition?.Calories,
									protien: mealData[3]?.nutrition?.Protein,
									carbs: mealData[3]?.nutrition?.Carbs,
									fat: mealData[3]?.nutrition?.Fat,
									link: recipeLinks[3]?.link,
								},
								{
									image: data?.image5,
									title: 'Dinner',
									mealname: mealData[4]?.meal,
									calories: mealData[4]?.nutrition?.Calories,
									protien: mealData[4]?.nutrition?.Protein,
									carbs: mealData[4]?.nutrition?.Carbs,
									fat: mealData[4]?.nutrition?.Fat,
									link: recipeLinks[4]?.link,
								},
							],
						},
					],
				};
				dispatch(mailDispatch(form));
			}
		} catch (error) {
			// alert('Failed');
			dispatch(
				bulkImageFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const desGenerateDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(desGenerateRequest());

		const { data } = await axiosInstance.post(
			'/recipegenerate',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(desGenerateSuccess(data));
		if (data.status === 'success') {
		}
	} catch (error) {
		alert('Failed');
		dispatch(
			desGenerateFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const recipeTitleGenerateDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(recipetitleGenerateRequest());

		const { data } = await axiosInstance.post(
			'/recipegenerate',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(recipetitleGenerateSuccess(data));
		if (data.status === 'success') {
		}
	} catch (error) {
		alert('Failed');
		dispatch(
			recipetitleGenerateFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const updateProfileDispatch =
	(bodyData, navigate) => async (dispatch) => {
		try {
			dispatch(profileRequest());

			const { data } = await axiosInstance.post(
				'/user/updateProfile',
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(profileSuccess(data));
			if (data.status === 'success') {
				// navigate('/dashboard');
				alert('Profile Update Successfully');
			}
		} catch (error) {
			alert('Failed');
			dispatch(
				profileFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const updateStatusUnsubDispatch =
	(bodyData, navigate) => async (dispatch) => {
		try {
			let form = {
				email: bodyData.email,
				status: 'inactive',
			};
			dispatch(statusUserRequest());

			const { data } = await axiosInstance.post(
				'/user/updateStatus',
				form,
				config
			);
			console.log('data', data);
			dispatch(statusUserSuccess(data));
			if (data.status === 'success') {
				navigate('/');
				alert('Your Response Submitted Thank You !');
			} else {
				alert('Failed');
			}
		} catch (error) {
			alert('Failed');
			dispatch(
				statusUserFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const eventDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(eventRequest());

		const { data } = await axiosInstance.post(
			'/eventstrigger',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(eventSuccess(data));
	} catch (error) {
		alert('Failed');
		dispatch(
			eventFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export default HomeReducer;
